/* You can add global styles to this file, and also import other style files */


/* html, body { height: 100%; } */

@import 'ag-grid-community/dist/styles/ag-grid.css';

/* @import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; */

@import 'ag-grid-community/dist/styles/ag-theme-balham.css';
.ag-theme-balham .ag-row-selected {
    background-color: #FBB03B;
}

.ag-header-cell-text {
    font-weight: bold;
    color: black;
}

.ag-theme-balham .ag-filter-apply-panel-button {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.ag-theme-balham .ag-filter-apply-panel-button:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}


/* ELIMINA LAS FLECHAS EN LOS INPUT NUMBERS DE FORMA GLOBAL*/

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"] {
    text-align: right;
}